<template>
  <footer class="footer">
    <div id="agency-info"></div>
    <div class="agency-info">
      <span class="title-container" @click="toggleAgencyInfo">
        <div class="title">Notre agence</div>
        <Icon
          name="chevron"
          class="icon-toggle"
          stroke="black"
          :class="{ open: isAgencyInfoOpen }"
        />
      </span>
      <p v-if="isAgencyInfoOpen">
        Du lundi au samedi de {{ agencyInfos.time.start }}h à {{ agencyInfos.time.end }}h
      </p>
      <NuxtLink v-if="isAgencyInfoOpen" :href="`mailto:${agencyInfos.emailAddress}`">
        <p>{{ agencyInfos.emailAddress }}</p>
      </NuxtLink>
      <p v-if="isAgencyInfoOpen">
        {{ agencyInfos.address }}
      </p>
    </div>
    <section v-if="isAgencyInfoOpen" class="continents-numbers">
      <span v-for="item in continents" :key="item.name" class="continent-item">
        <span class="name">{{ item.name }}</span>
        <NuxtLink :href="`tel:${item.phoneNumber}`" class="number">{{ item.phoneNumber }}</NuxtLink>
      </span>
    </section>
    <div class="separator" />
    <section class="menu-grid">
      <div v-for="(el, index) in menuElements" :key="el.title" class="menu-item">
        <span class="title-container" @click="toggleMenuElements(index)">
          <span class="title">{{ el.title }}</span>
          <Icon
            name="chevron"
            class="icon-toggle"
            stroke="black"
            :class="{ open: isMenuElementsOpen[index] }"
          />
        </span>
        <div v-if="isMenuElementsOpen[index]" class="elements">
          <NuxtLink v-for="item in el.elements" :key="item.link" class="link" :href="item.link"
            >{{ item.name }}
          </NuxtLink>
        </div>
        <div class="separator mobile" />
      </div>
      <div class="menu-item icons-menu">
        <div v-for="iconItem in menuLogosElements" :key="iconItem.title" class="icon-item">
          <span class="title-container">
            <span class="title">{{ iconItem.title }}</span>
          </span>
          <div class="icons">
            <NuxtLink v-for="icon in iconItem.elements" :key="icon.name" :href="icon.link">
              <NuxtImg :src="icon.name" :alt="icon.name" class="logo-image" loading="lazy" />
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="separator lower" />
      <div class="separator mobile" />
      <div class="socials">
        <NuxtLink v-for="icon in socials" :key="icon.icon" :href="icon.url">
          <Icon
            :key="icon.icon"
            :name="icon.icon"
            fill="var(--color-green)"
            width="40px"
            height="40px"
            class="icon"
          />
        </NuxtLink>
      </div>
    </section>
  </footer>
</template>

<script lang="ts" setup>
import { BREAKPOINTS } from '@/lib/variables';

import { Social } from '@/lib/strapi-types/components/Social';

import { FooterAgencyInfos, FooterContinent, FooterMenuElement } from '../lib/types/models/footer';

const { menuElements } = defineProps<{
  agencyInfos: FooterAgencyInfos;
  continents: FooterContinent[];
  menuElements: FooterMenuElement[];
  menuLogosElements: FooterMenuElement[];
  socials: Social[];
}>();

const windowStore = useWindow();

const isMenuElementsOpen = ref(Array(menuElements.length).fill(false));

const isAgencyInfoOpen = ref(false);

function toggleMenuElements(index: number): void {
  isMenuElementsOpen.value[index] = !isMenuElementsOpen.value[index];
}

function toggleAgencyInfo(): void {
  isAgencyInfoOpen.value = !isAgencyInfoOpen.value;
}

watch(windowStore, () => {
  if (windowStore.width > BREAKPOINTS.mobile) {
    isMenuElementsOpen.value = Array(menuElements.length).fill(true);
    isAgencyInfoOpen.value = true;
  }
});
</script>

<style scoped lang="scss">
@use '$/colors.scss';
@use '$/mouse-behavior.scss';

.footer {
  position: relative;

  flex-direction: column;
  gap: 32px;
  align-self: center;

  width: 100%;
  padding: 40px 64px 0;

  #agency-info {
    position: absolute;
    top: -80px;
  }

  .agency-info {
    flex-direction: column;

    p {
      font-size: 20px;
    }

    .title-container {
      justify-content: space-between;
      width: 100%;

      .title {
        font-size: 22px;
        font-weight: 600;
        color: colors.$green;
      }

      .icon-toggle {
        display: none;
      }
    }
  }

  .continents-numbers {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 32px;

    .continent-item {
      display: flex;
      flex-direction: column;
      font-size: 20px;

      .name {
        font-weight: 600;
      }
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: rgba(187 187 187 / 80%);

    &.lower {
      grid-column: 1 / 4;
      grid-row: 2;
      margin-top: 32px;
    }

    &.mobile {
      display: none;
    }
  }

  .menu-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr auto;
    column-gap: 32px;

    margin-bottom: 40px;

    .menu-item {
      flex-direction: column;
      gap: 16px;

      .elements {
        flex-direction: column;
        row-gap: 12px;
      }

      .icon-item {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;

        .title-container {
          pointer-events: none;

          .title {
            font-size: 22px;
            font-weight: 600;
            color: colors.$green;
          }

          .icon-toggle {
            display: none;
          }
        }

        .icons {
          gap: 12px;

          .logo-image {
            width: auto;
          }
        }

        &:nth-child(-n + 2) {
          .logo-image {
            height: 44px;
          }
        }

        &:nth-child(3) {
          .logo-image {
            height: 28px;
          }
        }

        &:nth-child(4) {
          .logo-image {
            height: 40px;
          }
        }
      }

      .title-container {
        pointer-events: none;

        .title {
          font-size: 22px;
          font-weight: 600;
          color: colors.$green;
        }

        .icon-toggle {
          display: none;
        }
      }

      .link {
        @include mouse-behavior.clickable-lightness-hover();

        color: black;
        text-decoration: none;
      }

      &.icons-menu {
        grid-column: 4;
        grid-row: 1 / 3;
        gap: 36px;
      }
    }

    .socials {
      grid-column: 1 / 3;
      grid-row: 2;
      gap: 30px;
      align-items: center;

      height: fit-content;
      margin-top: 64px;

      .icon {
        @include mouse-behavior.clickable-lightness-hover();
      }
    }
  }
}

@media (width <= 768px) {
  .footer {
    gap: 0;
    padding: 16px 16px 40px;

    .separator {
      &.mobile {
        display: flex;
      }

      &.lower {
        display: none;
      }
    }

    .agency-info {
      p {
        font-size: 16px;
      }

      .title-container {
        pointer-events: auto;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-bottom: 12px;

        .title {
          font-size: 20px;
        }

        .icon-toggle {
          display: flex;

          &.open {
            transform: rotate(180deg);
          }
        }
      }
    }

    .continents-numbers {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px 20px;
      padding: 12px 0;

      .continent-item {
        font-size: 16px;
      }
    }

    .menu-grid {
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-bottom: 0;

      .menu-item {
        flex-direction: column;
        gap: 0;
        padding-top: 16px;

        .title-container {
          pointer-events: auto;
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 100%;
          padding-bottom: 16px;

          .title {
            font-size: 20px;
          }

          .icon-toggle {
            display: flex;

            &.open {
              transform: rotate(180deg);
            }
          }
        }

        .elements {
          margin-top: 12px;

          .link {
            margin-bottom: 8px;
          }
        }

        .icon-item {
          cursor: pointer;
          flex-direction: column;
          gap: 0;

          .title-container {
            pointer-events: auto;

            .title {
              font-size: 22px;
              font-weight: 600;
              color: colors.$green;
            }

            .icon-toggle {
              display: flex;

              &.open {
                transform: rotate(180deg);
              }
            }
          }

          .icons {
            gap: 12px;
          }
        }

        .title {
          font-size: 22px;
          font-weight: 600;
          color: colors.$green;
        }

        .link {
          @include mouse-behavior.clickable-lightness-hover();

          color: black;
        }

        &.icons-menu {
          grid-column: 4;
          grid-row: 1 / 3;
          gap: 20px;
          padding-bottom: 16px;
        }
      }

      .socials {
        gap: 40px;
        align-self: stretch;
        justify-content: center;

        width: 100%;
        margin-top: 40px;

        .icon {
          @include mouse-behavior.clickable-lightness-hover();
        }
      }
    }
  }
}
</style>
